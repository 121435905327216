@use "@angular/material" as mat;
//primary colors
$primary_blue_50: #e6f0f9;
$primary_blue_100: #b1d0ec;
$primary_blue_200: #8bb9e2;
$primary_blue_300: #5599d5;
$primary_blue_400: #3585cd;
$primary_blue_500: #0267c1;
$primary_blue_600: #025eb0;
$primary_blue_700: #014989;
$primary_blue_800: #01396a;
$primary_blue_900: #012b51;

//secondary colors
$secondary_blue_50: #fef5e9;
$secondary_blue_100: #fddfbb;
$secondary_blue_200: #fbd09a;
$secondary_blue_300: #faba6c;
$secondary_blue_400: #f9ad50;
$secondary_blue_500: #f79824;
$secondary_blue_600: #e18a21;
$secondary_blue_700: #af6c1a;
$secondary_blue_800: #885414;
$secondary_blue_900: #68400f;

//text colors
$text_50: #e8e8e8;
$text_100: #b6b6b6;
$text_200: #939393;
$text_300: #626262;
$text_400: #434443;
$text_500: #141514;
$text_600: #121312;
$text_700: #0e0f0e;
$text_800: #0b0c0b;
$text_900: #080908;

//semantic colors
$negative: #ff5630;
$warning: #ffab00;
$positive: #36b37e;
$white: #ffffff;
$background: #f6f8fb;
$light-background: #eaeaea;
$less-opacity-black: #121212;
$background_orange: #fe7f2d;
$background_grey: #f0f0f0;
$background_gray: #eaecf0;

//black color codes
$black_700: #3f3b3b;
$black-600: #605f5f;
$black: #000;
$sidepanelBackground: #fefefe;

//gray color codes
$gray_900: #18181b;
$gray_600: #52525b;
$gray_500: #71717a;
$gray_200: #e4e4e7;
$gray_700: #344054;
$gray_1_200: #fcfcfd;
$gray_1_500: #667085;

@import "@angular/material/theming";

@include mat.core();

$primary-palette: (
  50: #e6f0f9,
  100: #b1d0ec,
  200: #8bb9e2,
  300: #5599d5,
  400: #3585cd,
  500: #0267c1,
  600: #025eb0,
  700: #014989,
  800: #01396a,
  900: #012b51,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$secondary-palette: (
  50: $secondary_blue_50,
  100: $secondary_blue_100,
  200: $secondary_blue_200,
  300: $secondary_blue_300,
  400: $secondary_blue_400,
  500: $secondary_blue_500,
  600: $secondary_blue_600,
  700: $secondary_blue_700,
  800: $secondary_blue_800,
  900: $secondary_blue_900,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$beos-primary: mat.define-palette($primary-palette);
$beos-secondary: mat.define-palette($secondary-palette);
$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $beos-primary,
      accent: $beos-secondary,
      warn: $my-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
@include mat.core-theme($my-theme);
@include mat.all-component-themes($my-theme);

/* ---- Mixins ---- */
@mixin primary-btn($bg, $font-color, $font-size, $font-family) {
  color: $font-color;
  background: $bg;
  font-size: $font-size;
  font-family: $font-family;
  border: 1px solid $font-color;
  font-weight: 600;
  border-radius: 4px;
  box-shadow: -5px 5px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
  padding: 8px 47px;
}
@mixin h180eb() {
  font-family: lexandExtraBold;
  font-size: 80px;
  color: $black_700;
  font-style: normal;
  font-weight: 800;
}
@mixin PlayfairDisplay() {
  font-family: lexandExtraBold;
  font-size: 20.19px;
  color: $black_700;
  font-style: italic;
  font-style: normal;
}
@mixin h260eb() {
  font-family: lexandExtraBold;
  font-size: 60px;
  color: $black_700;
  font-style: normal;
  font-weight: 800;
}
@mixin h340eb() {
  font-family: lexandExtraBold;
  font-size: 40px;
  color: $black_700;
  font-style: normal;
  font-weight: 800;
}

@mixin h430eb() {
  font-family: lexandExtraBold;
  font-size: 30px;
  color: $black_700;
  font-style: normal;
  font-weight: 800;
}

@mixin h524eb() {
  font-family: lexandExtraBold;
  font-size: 24px;
  color: $black_700;
  font-style: normal;
  font-weight: 800;
}

@mixin h620eb() {
  font-family: lexandExtraBold;
  font-size: 20px;
  color: $black_700;
  font-style: normal;
  font-weight: 800;
}

@mixin h620m() {
}
@mixin lead18eb() {
  font-family: lexandExtraBold;
  font-size: 18px;
  color: $black_700;
  font-style: normal;
  font-weight: 800;
}

@mixin body16eb() {
  font-family: lexandExtraBold;
  font-size: 16px;
  color: $black_700;
  font-style: normal;
  font-weight: 800;
}

@mixin caption14eb() {
  font-family: lexandExtraBold;
  font-size: 14px;
  color: $black_700;
  font-style: normal;
  font-weight: 800;
}

@mixin small12eb() {
  font-family: lexandExtraBold;
  font-size: 12px;
  color: $black_700;
  font-style: normal;
  font-weight: 800;
}

@mixin tiny10eb() {
  font-family: lexandExtraBold;
  font-size: 10px;
  color: $black_700;
  font-style: normal;
  font-weight: 800;
}

@mixin h180b() {
  font-family: lexandBold;
  font-size: 80px;
  color: $black_700;
  font-style: normal;
  font-weight: 700;
}
@mixin h260b() {
  font-family: lexandBold;
  font-size: 60px;
  color: $black_700;
  font-style: normal;
  font-weight: 700;
}
@mixin h340b() {
  font-family: lexandBold;
  font-size: 40px;
  color: $black_700;
  font-style: normal;
  font-weight: 700;
}

@mixin h430b() {
  font-family: lexandBold;
  font-size: 30px;
  color: $black_700;
  font-style: normal;
  font-weight: 700;
}

@mixin h524b() {
  font-family: lexandBold;
  font-size: 24px;
  color: $black_700;
  font-style: normal;
  font-weight: 700;
}

@mixin h620b() {
  font-family: lexandBold;
  font-size: 20px;
  color: $black_700;
  font-style: normal;
  font-weight: 700;
}

@mixin lead18b() {
  font-family: lexandBold;
  font-size: 18px;
  color: $black_700;
  font-style: normal;
  font-weight: 700;
}

@mixin body16b() {
  font-family: lexandBold;
  font-size: 16px;
  color: $black_700;
  font-style: normal;
  font-weight: 700;
}

@mixin caption14b() {
  font-family: lexandBold;
  font-size: 14px;
  color: $black_700;
  font-style: normal;
  font-weight: 700;
}

@mixin small12b() {
  font-family: lexandBold;
  font-size: 12px;
  color: $black_700;
  font-style: normal;
  font-weight: 700;
}

@mixin tiny10b() {
  font-family: lexandBold;
  font-size: 10px;
  color: $black_700;
  font-style: normal;
  font-weight: 700;
}
@mixin h180sb() {
  font-family: lexandSemiBold;
  font-size: 80px;
  color: $black_700;
  font-style: normal;
  font-weight: 600;
}
@mixin h260sb() {
  font-family: lexandSemiBold;
  font-size: 60px;
  color: $black_700;
  font-style: normal;
  font-weight: 600;
}
@mixin h340sb() {
  font-family: lexandSemiBold;
  font-size: 40px;
  color: $black_700;
  font-style: normal;
  font-weight: 600;
}

@mixin h430sb() {
  font-family: lexandSemiBold;
  font-size: 30px;
  color: $black_700;
  font-style: normal;
  font-weight: 600;
}

@mixin h524sb() {
  font-family: lexandSemiBold;
  font-size: 24px;
  color: $black_700;
  font-style: normal;
  font-weight: 600;
}

@mixin h620sb() {
  font-family: lexandSemiBold;
  font-size: 20px;
  color: $black_700;
  font-style: normal;
  font-weight: 600;
}

@mixin lead18sb() {
  font-family: lexandSemiBold;
  font-size: 18px;
  color: $black_700;
  font-style: normal;
  font-weight: 600;
}

@mixin body16sb() {
  font-family: lexandSemiBold;
  font-size: 16px;
  color: $black_700;
  font-style: normal;
  font-weight: 600;
}

@mixin caption14sb() {
  font-family: lexandSemiBold;
  font-size: 14px;
  color: $black_700;
  font-style: normal;
  font-weight: 600;
}

@mixin small12sb() {
  font-family: lexandSemiBold;
  font-size: 12px;
  color: $black_700;
  font-style: normal;
  font-weight: 600;
}

@mixin tiny10sb() {
  font-family: lexandSemiBold;
  font-size: 10px;
  color: $black_700;
  font-style: normal;
  font-weight: 600;
}
@mixin h180m() {
  font-family: lexandMedium;
  font-size: 80px;
  color: $black_700;
  font-style: normal;
  font-weight: 500;
}
@mixin h260m() {
  font-family: lexandMedium;
  font-size: 60px;
  color: $black_700;
  font-style: normal;
  font-weight: 500;
}
@mixin h340m() {
  font-family: lexandMedium;
  font-size: 40px;
  color: $black_700;
  font-style: normal;
  font-weight: 500;
}

@mixin h430m() {
  font-family: lexandMedium;
  font-size: 30px;
  color: $black_700;
  font-style: normal;
  font-weight: 500;
}

@mixin h524m() {
  font-family: lexandMedium;
  font-size: 24px;
  color: $black_700;
  font-style: normal;
  font-weight: 500;
}

@mixin h620m() {
  font-family: lexandMedium;
  font-size: 20px;
  color: $black_700;
  font-style: normal;
  font-weight: 500;
}

@mixin lead18m() {
  font-family: lexandMedium;
  font-size: 18px;
  color: $black_700;
  font-style: normal;
  font-weight: 500;
}

@mixin body16m() {
  font-family: lexandMedium;
  font-size: 16px;
  color: $black_700;
  font-style: normal;
  font-weight: 500;
}

@mixin caption14m() {
  font-family: lexandMedium;
  font-size: 14px;
  color: $black_700;
  font-style: normal;
  font-weight: 500;
}

@mixin caption14ms() {
  font-family: lexandMedium;
  font-size: 14px;
  color: $text_300;
  font-style: normal;
  font-weight: 500;
}

@mixin small12m() {
  font-family: lexandMedium;
  font-size: 12px;
  color: $black_700;
  font-style: normal;
  font-weight: 500;
}

@mixin tiny10m() {
  font-family: lexandMedium;
  font-size: 10px;
  color: $black_700;
  font-style: normal;
  font-weight: 500;
}
@mixin h180r() {
  font-family: lexandReg;
  font-size: 80px;
  color: $black_700;
  font-style: normal;
  font-weight: 400;
}
@mixin h260r() {
  font-family: lexandReg;
  font-size: 60px;
  color: $black_700;
  font-style: normal;
  font-weight: 400;
}
@mixin h340r() {
  font-family: lexandReg;
  font-size: 40px;
  color: $black_700;
  font-style: normal;
  font-weight: 400;
}

@mixin h430r() {
  font-family: lexandReg;
  font-size: 30px;
  color: $black_700;
  font-style: normal;
  font-weight: 400;
}

@mixin h524r() {
  font-family: lexandReg;
  font-size: 24px;
  color: $black_700;
  font-style: normal;
  font-weight: 400;
}

@mixin h620r() {
  font-family: lexandReg;
  font-size: 20px;
  color: $black_700;
  font-style: normal;
  font-weight: 400;
}

@mixin lead18r() {
  font-family: lexandReg;
  font-size: 18px;
  color: $black_700;
  font-style: normal;
  font-weight: 400;
}

@mixin body16r() {
  font-family: lexandReg;
  font-size: 16px;
  color: $black_700;
  font-style: normal;
  font-weight: 400;
}

@mixin caption14r() {
  font-family: lexandReg;
  font-size: 14px;
  color: $black_700;
  font-style: normal;
  font-weight: 400;
}

@mixin small12r() {
  font-family: lexandReg;
  font-size: 12px;
  color: $black_700;
  font-style: normal;
  font-weight: 400;
}

@mixin tiny10r() {
  font-family: lexandReg;
  font-size: 10px;
  color: $black_700;
  font-style: normal;
  font-weight: 400;
}

@mixin h180l() {
  font-family: lexandLigh;
  font-size: 80px;
  color: $black_700;
  font-style: normal;
  font-weight: 300;
}
@mixin h260l() {
  font-family: lexandLigh;
  font-size: 60px;
  color: $black_700;
  font-style: normal;
  font-weight: 300;
}
@mixin h340l() {
  font-family: lexandLigh;
  font-size: 40px;
  color: $black_700;
  font-style: normal;
  font-weight: 300;
}

@mixin h430l() {
  font-family: lexandLigh;
  font-size: 30px;
  color: $black_700;
  font-style: normal;
  font-weight: 300;
}

@mixin h524l() {
  font-family: lexandLigh;
  font-size: 24px;
  color: $black_700;
  font-style: normal;
  font-weight: 300;
}

@mixin h620l() {
  font-family: lexandLigh;
  font-size: 20px;
  color: $black_700;
  font-style: normal;
  font-weight: 300;
}

@mixin lead18l() {
  font-family: lexandLigh;
  font-size: 18px;
  color: $black_700;
  font-style: normal;
  font-weight: 300;
}

@mixin body16l() {
  font-family: lexandLigh;
  font-size: 16px;
  color: $black_700;
  font-style: normal;
  font-weight: 300;
}

@mixin caption14l() {
  font-family: lexandLigh;
  font-size: 14px;
  color: $black_700;
  font-style: normal;
  font-weight: 300;
}

@mixin small12l() {
  font-family: lexandLigh;
  font-size: 12px;
  color: $black_700;
  font-style: normal;
  font-weight: 300;
}

@mixin tiny10l() {
  font-family: lexandLigh;
  font-size: 10px;
  color: $black_700;
  font-style: normal;
  font-weight: 300;
}

@mixin h180el() {
  font-family: lexandExtraLight;
  font-size: 80px;
  color: $black_700;
  font-style: normal;
  font-weight: 200;
}
@mixin h260el() {
  font-family: lexandExtraLight;
  font-size: 60px;
  color: $black_700;
  font-style: normal;
  font-weight: 200;
}
@mixin h340el() {
  font-family: lexandExtraLight;
  font-size: 40px;
  color: $black_700;
  font-style: normal;
  font-weight: 200;
}

@mixin h430el() {
  font-family: lexandExtraLight;
  font-size: 30px;
  color: $black_700;
  font-style: normal;
  font-weight: 200;
}

@mixin h524el() {
  font-family: lexandExtraLight;
  font-size: 24px;
  color: $black_700;
  font-style: normal;
  font-weight: 200;
}

@mixin h620el() {
  font-family: lexandExtraLight;
  font-size: 20px;
  color: $black_700;
  font-style: normal;
  font-weight: 200;
}

@mixin lead18el() {
  font-family: lexandExtraLight;
  font-size: 18px;
  color: $black_700;
  font-style: normal;
  font-weight: 200;
}

// @mixin lead18sb() {
//   font-family: lexandExtraLight;
//   font-size: 18px;
//   color: $black_700;
//   font-style: normal;
//   font-weight: 200;
// }

@mixin body16el() {
  font-family: lexandExtraLight;
  font-size: 16px;
  color: $black_700;
  font-style: normal;
  font-weight: 200;
}

@mixin caption14el() {
  font-family: lexandExtraLight;
  font-size: 14px;
  color: $black_700;
  font-style: normal;
  font-weight: 200;
}

@mixin small12el() {
  font-family: lexandExtraLight;
  font-size: 12px;
  color: $black_700;
  font-style: normal;
  font-weight: 200;
}

@mixin tiny10el() {
  font-family: lexandExtraLight;
  font-size: 10px;
  color: $black_700;
  font-style: normal;
  font-weight: 200;
}

@mixin textlgm() {
  font-family: lexandMedium;
  font-size: 18px;
  color: $gray_900;
  font-style: normal;
  font-weight: 500;
}

@mixin textxsm() {
  font-family: lexandMedium;
  font-size: 12px;
  color: $secondary_blue_500;
  font-style: normal;
  font-weight: 500;
}

@mixin textmdn() {
  font-family: lexandReg;
  font-size: 16px;
  color: $gray_500;
  font-style: normal;
  font-weight: 400;
}

@mixin textsmn() {
  font-family: lexandReg;
  font-size: 14px;
  color: $gray_500;
  font-style: normal;
  font-weight: 400;
}

@mixin textsmm() {
  font-family: lexandMedium;
  font-size: 14px;
  color: $gray_700;
  font-style: normal;
  font-weight: 500;
}

@mixin fieldh() {
  font-family: lexandMedium;
  font-size: 14px;
  color: $text_400;
  font-style: normal;
  font-weight: 400;
}

@mixin blogh() {
  font-family: lexandReg;
  font-size: 14px;
  color: $text_400;
  font-style: normal;
  font-weight: 400;
}

@mixin blogAuthor() {
  font-family: lexandReg;
  font-size: 14px;
  color: $text_300;
  font-style: normal;
  font-weight: 400;
}

@mixin blogResident() {
  font-family: lexandReg;
  font-size: 14px;
  color: $text_300;
  font-style: normal;
  font-weight: 400;
  background-color: $text_50;
}

@mixin viewtextheading() {
  font-family: lexandMedium;
  font-size: 14px;
  color: $text_400;
  font-style: normal;
  font-weight: 500;
}

@mixin fieldph() {
  font-family: lexandReg;
  font-size: 16px;
  color: $text_100;
  font-style: normal;
  font-weight: 400;
}

@mixin viewheading() {
  font-family: lexandReg;
  font-size: 16px;
  color: $text_400;
  font-style: normal;
  font-weight: 500;
}

@mixin viewtexth() {
  font-family: lexandMedium;
  font-size: 16px;
  color: $text_500;
  font-style: normal;
  font-weight: 500;
}

@mixin viewtab() {
  font-family: lexandReg;
  font-size: 14px;
  color: $text_500;
  font-style: normal;
  font-weight: 400;
  background-color: $text_50;
}

@mixin blogp() {
  font-family: lexandReg;
  font-size: 14px;
  color: $text_500;
  font-style: normal;
  font-weight: 400;
}

@mixin blogsession() {
  font-family: lexandMedium;
  font-size: 24px;
  color: $text_500;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
}

@mixin seeall() {
  font-family: lexandMedium;
  font-size: 16px;
  color: $primary_blue_500;
  font-style: normal;
  font-weight: 500;
}

@mixin text-size($size, $font-family, $color, $font-style, $weight) {
  font-family: $font-family;
  font-size: $size;
  color: $color;
  font-style: $font-style;
  font-weight: $weight;
}
.input-box {
  input {
    font-weight: 500 !important;
    color: #121212 !important;
    font-family: lexandMedium;
  }
}
.body-section {
  input {
    font-weight: 500 !important;
    color: #121212 !important;
    font-family: lexandMedium;
  }
}

@mixin outlinedButton($color: $primary_blue_500) {
  border: 1px solid $color;
  color: $color;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
}

@mixin flatButton($color: $primary_blue_500) {
  border: 1px solid $color;
  padding: 8px 10px;
  border-radius: 5px;
  background-color: $color;
  cursor: pointer;
}
