/* You can add global styles to this file, and also import other style files */

@import "@angular/material/theming";


body{
    width: 100%;
    height: calc(100vh - 0px);
}
.cursorPointer {
    cursor: pointer !important;
}

//component library related styles
/* You can add global styles to this file, and also import other style files */

.cursor-pointer {
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 0.1px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

::-webkit-scrollbar {
  display: none;
}

.mat-mdc-text-field-wrapper{
  height: 36px !important;
}
.mat-mdc-form-field-flex{
  height: 100% !important;
}
.mat-mdc-select-trigger{
  height: 36px !important;
}
.mat-mdc-form-field-infix{
  padding: 0px !important;
}

.heading-large{
  font-size: calc(36px* var(--head-fontSize, 1));
  line-height: calc(42px* var(--head-fontHeight, 1));
  font-weight: 600;
}
.body-large{
  font-size: calc(20px* var(--body-fontSize, 1));
  line-height: calc(30px* var(--body-fontHeight, 1));
  font-weight: 400;
}
.body-desc{
  font-size: calc(16px* var(--body-fontSize, 1));
  font-weight: 400;
}
button{
  font-size: 1.25rem !important;
  width: 100% !important;
}
.heading-medium {
  font-size: calc(30px* var(--head-fontSize, 1));
  line-height: calc(38px* var(--head-fontHeight, 1));
  font-weight: 600;
}
.heading-small{
  font-size: calc(20px* var(--head-fontSize, 1));
  line-height: calc(38px* var(--head-fontHeight, 1));
  font-weight: 500;
}
// h1,h2,h3,h4,h5,h6
@media only screen and (min-width : 768px){
  .heading-large{
    font-size: calc(46px* var(--head-fontSize, 1));
    line-height: calc(54px* var(--head-fontHeight, 1));
    font-weight: 600;
  }
  .heading-medium {
    font-size: calc(30px* var(--head-fontSize, 1));
    line-height: calc(38px* var(--head-fontHeight, 1));
    font-weight: 600;
  }
}

@media only screen and (min-width : 1280px){
  .heading-large{
    font-size: calc(48px* var(--head-fontSize, 1));
    line-height: calc(65px* var(--head-fontHeight, 1));
    font-weight: 400;
  }
  .body-large{
    font-size: calc(16px* var(--body-fontSize, 1));
    line-height: calc(30px* var(--body-fontHeight, 1));
  }
  .heading-medium {
    font-size: calc(30px* var(--head-fontSize, 1));
    line-height: calc(38px* var(--head-fontHeight, 1));
    font-weight: 400;
  }
  .body-desc{
    font-size: calc(18px* var(--body-fontSize, 1));
    font-weight: 400;
  }
}
