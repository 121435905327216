@import "./theme.scss";

//inter font-family
@font-face {
  font-family: interBold;
  src: url("./../../../assets/fonts/inter/Inter-Bold.ttf");
}

@font-face {
  font-family: interExtraBold;
  src: url("./../../../assets/fonts/inter/Inter-ExtraBold.ttf");
}

@font-face {
  font-family: interExtraLight;
  src: url("./../../../assets/fonts/inter/Inter-ExtraLight.ttf");
}

@font-face {
  font-family: interLight;
  src: url("./../../../assets/fonts/inter/Inter-Light.ttf");
}

@font-face {
  font-family: interMedium;
  src: url("./../../../assets/fonts/inter/Inter-Medium.ttf");
}

@font-face {
  font-family: interReg;
  src: url("./../../../assets/fonts/inter/Inter-Regular.ttf");
}

@font-face {
  font-family: interSemiBold;
  src: url("./../../../assets/fonts/inter/Inter-SemiBold.ttf");
}

@font-face {
  font-family: interThin;
  src: url("./../../../assets/fonts/inter/Inter-Thin.ttf");
}

//jakarta font-family
@font-face {
  font-family: jakartaBold;
  src: url("./../../../assets/fonts/jakarta_sans/PlusJakartaSans-Bold.ttf");
}

@font-face {
  font-family: jakartaExtraBold;
  src: url("./../../../assets/fonts/jakarta_sans/PlusJakartaSans-ExtraBold.ttf");
}

@font-face {
  font-family: jakartaExtraLight;
  src: url("./../../../assets/fonts/jakarta_sans/PlusJakartaSans-ExtraLight.ttf");
}

@font-face {
  font-family: jakartaLight;
  src: url("./../../../assets/fonts/jakarta_sans/PlusJakartaSans-Light.ttf");
}

@font-face {
  font-family: jakartaMedium;
  src: url("./../../../assets/fonts/jakarta_sans/PlusJakartaSans-Medium.ttf");
}

@font-face {
  font-family: jakartaReg;
  src: url("./../../../assets/fonts/jakarta_sans/PlusJakartaSans-Medium.ttf");
}

@font-face {
  font-family: jakartaSemiBold;
  src: url("./../../../assets/fonts/jakarta_sans/PlusJakartaSans-SemiBold.ttf");
}

//other font-family
@font-face {
  font-family: lexandReg;
  src: url("./../../../assets/fonts/Lexand/Lexend-Regular.ttf");
}

@font-face {
  font-family: lexandSemiBold;
  src: url("./../../../assets/fonts/Lexand/Lexend-SemiBold.ttf");
}

@font-face {
  font-family: lexandBold;
  src: url("./../../../assets/fonts/Lexand/Lexend-Bold.ttf");
}

@font-face {
  font-family: lexandExtraBold;
  src: url("./../../../assets/fonts/Lexand/Lexend-ExtraBold.ttf");
}

@font-face {
  font-family: lexandLigh;
  src: url("./../../../assets/fonts/Lexand/Lexend-Light.ttf");
}

@font-face {
  font-family: lexandMedium;
  src: url("./../../../assets/fonts/Lexand/Lexend-Medium.ttf");
}

@font-face {
  font-family: lexandBlack;
  src: url("./../../../assets/fonts/Lexand/Lexend-Black.ttf");
}

@font-face {
  font-family: lexandExtraLight;
  src: url("./../../../assets/fonts/Lexand/Lexend-ExtraLight.ttf");
}

@font-face {
  font-family: lexandThin;
  src: url("./../../../assets/fonts/Lexand/Lexend-Thin.ttf");
}

//website font families
@font-face {
  font-family: opensans_reg400;
  src: url("./../../../assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: opensans_semibold600;
  src: url("./../../../assets/fonts/Open_Sans/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: merriweather_bold700;
  src: url("./../../../assets/fonts/Merriweather/Merriweather-Bold.ttf");
}

@font-face {
  font-family: sourceserifpro_semibold600;
  src: url("./../../../assets/fonts/Source_Serif_Pro/SourceSerifPro-SemiBold.ttf");
}

@font-face {
  font-family: lato_regular400;
  src: url("./../../../assets/fonts/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: roboto_regular400;
  src: url("./../../../assets/fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: nunito_sans_bold700;
  src: url("./../../../assets/fonts/Nunito_sans/NunitoSans-Bold.ttf");
}

@font-face {
  font-family: nunito_reg400;
  src: url("./../../../assets/fonts/Nunito/Nunito-Regular.ttf");
}

@font-face {
  font-family: bitter_semibold600;
  src: url("./../../../assets/fonts/Bitter/Bitter-SemiBold.ttf");
}

@font-face {
  font-family: firasans_reg400;
  src: url("./../../../assets/fonts/Fira_sans/FiraSans-Regular.ttf");
}

.cursor-pointer {
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 0.1px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $white;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $white;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $white;
}

::-webkit-scrollbar {
  display: none;
}

button {
  cursor: pointer;
}

button:disabled {
  background-color: $gray_500 !important;
  border-color: $gray_500 !important;
  color: $white !important;
  cursor: auto;
}

.slick-prev {
  left: -15px;
}

.slick-next {
  right: -15px;
}

.slick-prev:before,
.slick-next:before {
  color: black !important;
}
.mat-grid-tile-content {
  width: 90%;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  table {
    overflow: scroll;
    width: 115% !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
// input[type="number"] {
//   // -moz-appearance: textfield;
// }
input[type=file],
/* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

//extra bold classes

.h1_80_extraBold {
  line-height: 81px;
  letter-spacing: -0.9px;
  @include text-size(80px, lexandExtraBold, $black_700, normal, 800);
}

.h2_60_extraBold {
  line-height: 70px;
  letter-spacing: -0.7px;
  @include text-size(60px, lexandExtraBold, $black_700, normal, 800);
}

.h3_40_extraBold {
  line-height: 48px;
  letter-spacing: -0.4px;
  @include text-size(40px, lexandExtraBold, $black_700, normal, 800);
}

.h4_30_extraBold {
  line-height: 37px;
  letter-spacing: -0.35px;
  @include text-size(30px, lexandExtraBold, $black_700, normal, 800);
}

.h5_24_extraBold {
  line-height: 34px;
  @include text-size(24px, lexandExtraBold, $black_700, normal, 800);
}

.h6_20_extraBold {
  line-height: 30px;
  @include text-size(20px, lexandExtraBold, $black_700, normal, 800);
}

.extrabold_18_regular {
  line-height: 32px;
  @include text-size(18px, lexandExtraBold, $black_700, normal, 800);
}

.extrabold_16_body {
  line-height: 28px;
  @include text-size(16px, lexandExtraBold, $black_700, normal, 800);
}

//bold classes

.h1_80_bold {
  line-height: 81px;
  letter-spacing: -0.9px;
  @include text-size(80px, lexandBold, $black_700, normal, 700);
}

.h2_60_bold {
  line-height: 70px;
  letter-spacing: -0.7px;
  @include text-size(60px, lexandBold, $black_700, normal, 700);
}

.h3_40_bold {
  line-height: 48px;
  letter-spacing: -0.4px;
  @include text-size(40px, lexandBold, $black_700, normal, 700);
}

.h4_30_bold {
  line-height: 37px;
  letter-spacing: -0.35px;
  @include text-size(30px, lexandBold, $black_700, normal, 700);
}

.h5_24_bold {
  line-height: 34px;
  @include text-size(24px, lexandBold, $black_700, normal, 700);
}

.h6_20_bold {
  line-height: 30px;
  @include text-size(20px, lexandBold, $black_700, normal, 700);
}

.bold_18_regular {
  line-height: 32px;
  @include text-size(18px, lexandBold, $black_700, normal, 700);
}

.bold_16_body {
  line-height: 28px;
  @include text-size(16px, lexandBold, $black_700, normal, 700);
}

//Semi Bold Size Classes

.h1_80_semiBold {
  line-height: 81px;
  letter-spacing: -0.9px;
  @include text-size(80px, lexandSemiBold, $black_700, normal, 600);
}

.h2_60_semiBold {
  line-height: 70px;
  letter-spacing: -0.7px;
  @include text-size(60px, lexandSemiBold, $black_700, normal, 600);
}

.h3_40_semiBold {
  line-height: 48px;
  letter-spacing: -0.4px;
  @include text-size(40px, lexandSemiBold, $black_700, normal, 600);
}

.h4_30_semiBold {
  line-height: 37px;
  letter-spacing: -0.35px;
  @include text-size(30px, lexandSemiBold, $black_700, normal, 600);
}

.h5_24_semiBold {
  line-height: 34px;
  @include text-size(24px, lexandSemiBold, $black_700, normal, 600);
}

.h6_20_semiBold {
  line-height: 30px;
  @include text-size(20px, lexandSemiBold, $black_700, normal, 600);
}

.semiBold_18_regular {
  line-height: 32px;
  @include text-size(18px, lexandSemiBold, $black_700, normal, 600);
}

.semiBold_16_body {
  line-height: 28px;
  @include text-size(16px, lexandSemiBold, $black_700, normal, 600);
}

//Medium Size Classes

.h1_80_medium {
  line-height: 81px;
  letter-spacing: -0.9px;
  @include text-size(80px, lexandMedium, $black_700, normal, 300);
}

.h2_60_medium {
  line-height: 70px;
  letter-spacing: -0.7px;
  @include text-size(60px, lexandMedium, $black_700, normal, 500);
}

.h3_40_medium {
  line-height: 48px;
  letter-spacing: -0.4px;
  @include text-size(40px, lexandMedium, $black_700, normal, 500);
}

.h4_30_medium {
  line-height: 37px;
  letter-spacing: -0.35px;
  @include text-size(30px, lexandMedium, $black_700, normal, 500);
}

.h5_24_medium {
  line-height: 34px;
  @include text-size(24px, lexandMedium, $black_700, normal, 500);
}

.h6_20_medium {
  line-height: 30px;
  @include text-size(20px, lexandMedium, $black_700, normal, 500);
}

.medium_18_regular {
  line-height: 32px;
  @include text-size(18px, lexandMedium, $black_700, normal, 500);
}

.medium_16_body {
  line-height: 28px;
  @include text-size(16px, lexandMedium, $black_700, normal, 500);
}

//Regular Size Classes

.h1_80_regular {
  line-height: 81px;
  letter-spacing: -0.9px;
  @include text-size(80px, lexandMedium, $black_700, normal, 400);
}

.h2_60_regular {
  line-height: 70px;
  letter-spacing: -0.7px;
  @include text-size(60px, lexandReg, $black_700, normal, 400);
}

.h3_40_regular {
  line-height: 48px;
  letter-spacing: -0.4px;
  @include text-size(40px, lexandReg, $black_700, normal, 400);
}

.h4_30_regular {
  line-height: 37px;
  letter-spacing: -0.35px;
  @include text-size(30px, lexandReg, $black_700, normal, 400);
}

.h5_24_regular {
  line-height: 34px;
  @include text-size(24px, lexandReg, $black_700, normal, 400);
}

.h6_20_regular {
  line-height: 30px;
  @include text-size(20px, lexandReg, $black_700, normal, 400);
}

.regular_18_regular {
  line-height: 32px;
  @include text-size(18px, lexandReg, $black_700, normal, 400);
}

.regular_16_body {
  line-height: 28px;
  @include text-size(16px, lexandReg, $black_700, normal, 400);
}

//Light Size Classes

.h1_80_light {
  line-height: 81px;
  letter-spacing: -0.9px;
  @include text-size(80px, lexandLigh, $black_700, normal, 300);
}

.h2_60_light {
  line-height: 70px;
  letter-spacing: -0.7px;
  @include text-size(60px, lexandLigh, $black_700, normal, 300);
}

.h3_40_light {
  line-height: 48px;
  letter-spacing: -0.4px;
  @include text-size(40px, lexandLigh, $black_700, normal, 300);
}

.h4_30_light {
  line-height: 37px;
  letter-spacing: -0.35px;
  @include text-size(30px, lexandLigh, $black_700, normal, 300);
}

.h5_24_light {
  line-height: 34px;
  @include text-size(24px, lexandLigh, $black_700, normal, 300);
}

.h6_20_light {
  line-height: 30px;
  @include text-size(20px, lexandLigh, $black_700, normal, 300);
}

.light_18_regular {
  line-height: 32px;
  @include text-size(18px, lexandLigh, $black_700, normal, 300);
}

.light_16_body {
  line-height: 28px;
  @include text-size(16px, lexandLigh, $black_700, normal, 300);
}

//button hover
// button:hover{
//   animation: btnglowing 1300ms infinite;
// }
@keyframes btnglowing {
  0% {
    background-color: $white;
    box-shadow: 0 0 3px $primary_blue_500;
  }

  50% {
    background-color: $white;
    box-shadow: 0 0 10px $primary_blue_500;
  }

  100% {
    background-color: $white;
    box-shadow: 0 0 3px $primary_blue_500;
  }
}

//extra light classes

.extraLight_18 {
  line-height: 32px;
  @include text-size(18px, lexandExtraLight, $black_700, normal, 200);
}

.extraLight_16_body {
  line-height: 32px;
  @include text-size(18px, lexandExtraLight, $black_700, normal, 200);
}

.filter-dialog-container {
  .mat-dialog-container {
    padding: 0px;
    border-radius: 20px;
  }
}

.slick-dots {
  position: relative !important;
  bottom: 0px !important;
}

.domain-input {
  .mat-mdc-form-field-flex {
    height: 40px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mat-mdc-form-field-infix {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 0px !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--textarea)
    .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    height: 40px;
    @include body16r();
    color: $text_300;
    letter-spacing: 0px;
  }
}

// .mat-mdc-form-field-flex {
//   height: 40px;
// }
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 0px;
  display: flex;
  // justify-content: center;
  align-items: center;
  min-height: 0px !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea)
  .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control,
.mat-mdc-select-trigger {
  height: 40px !important;
}

.custom-mat-form-field-size {
  .mdc-text-field--no-label:not(.mdc-text-field--textarea)
    .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control,
  .mat-mdc-select-trigger {
    font-size: 14px;
  }
}

.days_dropdown {
  .mat-mdc-text-field-wrapper {
    background-color: $white;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
      0px 0.5px 2px rgba(96, 97, 112, 0.16);
    border-radius: 4px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.input_card {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.google_search {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-form-field-flex {
    height: 30px;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--textarea)
    .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control,
  .mat-mdc-select-trigger {
    height: 30px !important;
  }

  .mdc-text-field {
    background: $text_50 !important;
    border-radius: 10px;
  }
}

.url_input {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.search_domain {
  .mat-mdc-text-field-wrapper {
    background-color: $white;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.taking_input,
.location_input {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.table-section {
  table {
    mat-mdc-checkbox
      .mdc-checkbox
      .mdc-checkbox__native-control:enabled:checked
      ~ .mdc-checkbox__background {
      background-color: #0267c1;
      border-color: #0267c1;
    }

    .mat-mdc-slide-toggle.mat-accent {
      --mdc-switch-selected-handle-color: #0267c1;
      --mdc-switch-selected-track-color: #0267c1;
      --mdc-switch-selected-hover-track-color: #0267c1;
      --mdc-switch-selected-pressed-track-color: #0267c1;
      --mdc-switch-selected-focus-track-color: #0267c1;
    }

    .mat-mdc-slide-toggle.mat-accent {
      --mdc-switch-selected-focus-handle-color: #0267c1;
      --mdc-switch-selected-hover-handle-color: #0267c1;
      --mdc-switch-selected-pressed-handle-color: #0267c1;
    
    }
  }
}

.business-field-section {
  mat-form-field {
    .mat-mdc-form-field-subscript-wrapper {
      display: none !important;
    }
  }
}

.create-content-section {
  .form-field {
    .mat-mdc-form-field-subscript-wrapper {
      display: none !important;
    }
  }
}

.mat-mdc-option .mdc-list-item__primary-text {
  @include body16r();
  color: $text_300 !important;
  letter-spacing: 0px !important;
  font-family: lexandReg !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  width: 100%;
}

.mat-mdc-checkbox label {
  @include caption14r();
  letter-spacing: 0px !important;
  font-family: lexandReg !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.mat-form-field-label {
  @include caption14r();
  letter-spacing: 0px !important;
  font-family: lexandReg !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.whitebackgroundInput {
  .mat-mdc-text-field-wrapper {
    background: $white !important;
  }

  span.mat-mdc-select-min-line {
    color: black !important;
    font-weight: 700;
  }
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $primary_blue_500;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: $primary_blue_500;
  background-color: $primary_blue_500;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: $primary_blue_500;
}

//loader
.api_loader {
  .dots-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .dot {
    height: 15px;
    width: 15px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #b3d4fc;
    animation: pulse 1.5s infinite ease-in-out;
  }

  .dot:last-child {
    margin-right: 0;
  }

  .dot:nth-child(1) {
    animation-delay: -0.3s;
  }

  .dot:nth-child(2) {
    animation-delay: -0.1s;
  }

  .dot:nth-child(3) {
    animation-delay: 0.1s;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.8);
      background-color: #b3d4fc;
      box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
    }

    50% {
      transform: scale(1.2);
      background-color: #6793fb;
      box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
    }

    100% {
      transform: scale(0.8);
      background-color: #b3d4fc;
      box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
    }
  }
}

.mdc-switch__ripple {
  left: 0% !important;
}

.dropdown {
  .mat-mdc-select-value {
    color: white;
    @include text-size(16px, interReg, $white, normal, 700);
  }

  .mat-mdc-select-arrow {
    color: white;
    @include text-size(16px, interReg, $white, normal, 700);
  }
}

.background-white-chip {
  .mdc-text-field--filled:not {
    background-color: white !important;
  }
}

.custom-mat-form-field {
  .mat-mdc-form-field-subscript-wrapper {
    height: 0;
    margin-top: 5px;
  }
}

.custom-mat-form-field-datepicker {
  .mdc-notched-outline {
    height: 80%;
  }
}

.custom-mat-datepicker-field {
  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 40px;
    height: 40px;
    padding: 5px;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  width: 100% !important;
}
.slick-initialized .slick-slide {
  width: 33% !important;
}
.slick-wrapper{
  width: 100% !important;
}

.media-selector-form-field {
  .mat-mdc-form-field-subscript-wrapper {
    height: 0 !important;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mat-mdc-form-field-infix {
    height: 100%;
  }

  .mat-mdc-form-field-flex {
    height: 100%;
  }
}

.custom-mat-chip-styles {
  .mat-mdc-chip-set .mdc-evolution-chip-set__chips {
    flex-wrap: nowrap !important;
    overflow-x: scroll !important; 
  }

  .mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic {
    display: none;
  }

  .mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary {
    padding-left: 12px;
  }
}